<template>
  <div class="grid-widget">
    <smart-widget-grid
      :layout.sync="layout"
      :col-num="colNum"
      :row-height="30"
      :is-draggable="draggable"
      :is-resizable="resizable"
      :autoSize="true"
      :vertical-compact="true"
      :use-css-transforms="true"
      :margin="[15, 15]"
    >
      <smart-widget
        v-for="(item, index) in devices"
        :key="index"
        :slot="index"
        :title="item.title"
      >
        <div class="contens mb-2">
          <div class="">
            <span class="title__card">
              Fecha y hora: {{ item.timestamp }}
            </span>
          </div>
          <div class="">
            <span class="title__card"> Mac: {{ item.mac }} </span>
          </div>
          <div>
            <button
              @click="sendStatistics(item.mac, item.pcbname, item.enterprise)"
              class="btn__statistics"
            >
              <i class="fas fa-chart-area"></i>
              Estadìsticas
            </button>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-4 col-md-6 col-12 mb-3"
            v-for="(sensor, ind) in item.sensor"
            :key="ind"
          >
            <!-- Temperatura y Humedad -->
            <div v-if="sensor.type == 1">
              <div class="card__admin text-center p-2 mb-3">
                <div class="row">
                  <div class="col-6 mb-2 p-0">
                    <div class="title__card mb-2">Temperatura</div>
                    <div class="title__battery">
                      {{sensor.values[0].value + sensor.values[0].unit}}
                    </div>
                    <v-gauge
                      :value="sensor.values[0].value"
                      :width="width1"
                      :height="height1"
                      :options="options3"
                      :unit="sensor.values[0].unit"
                      top
                    />
                    <div class="limits2">
                      <span class="limit__alarma2">
                        Min:
                        {{ sensor.values[0].Lmin + sensor.values[0].unit }}
                      </span>
                      <span class="limit__alarma2">
                        Max:
                        {{ sensor.values[0].Lmax + sensor.values[0].unit }}
                      </span>
                    </div>
                  </div>
                  <div class="col-6 p-0">
                    <div class="title__card mb-2">Humedad</div>
                    <fill-gauge :id="'fillgauge1'+ index" :value="sensor.values[1].value" />
                    <div class="limits2">
                      <span class="limit__alarma3">
                        Min:
                        {{ sensor.values[1].Lmin + sensor.values[1].unit }}
                      </span>
                      <span class="limit__alarma3">
                        Max:
                        {{ sensor.values[1].Lmax + sensor.values[1].unit }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Temperatura -->
            <div v-if="sensor.type == 13">
              <div class="card__admin text-center p-2">
                <div class="title__card mb-2">Temperatura</div>
                <div class="title__battery">
                  {{sensor.values[0].value + sensor.values[0].unit}}
                </div>
                <v-gauge
                  :value="sensor.values[0].value"
                  :width="width"
                  :height="height"
                  :options="options2"
                  :unit="sensor.values[0].unit"
                  top
                />
                <div class="limits">
                  <span class="limit__alarma">
                    Min: {{ sensor.values[0].Lmin + sensor.values[0].unit }}
                  </span>
                  <span class="limit__alarma">
                    Max: {{ sensor.values[0].Lmax + sensor.values[0].unit }}
                  </span>
                </div>
              </div>
            </div>
            <!-- Indicador de energía -->
            <div v-if="sensor.type == 10 && sensor.number == 1">
              <div class="card__admin text-center p-2">
                <div class="title__card mb-2">Indicador de energía</div>
                <Ligthbuld :value="sensor.values[0].value == 1 ? true : false" />
              </div>
            </div>
            <!-- Indicador de planta electrica -->
            <div v-if="sensor.type == 10 && sensor.number == 2">
              <div class="card__admin text-center p-2">
                <div class="title__card mb-2">
                  Indicador de planta eléctrica
                </div>
                <Powerplant :value="sensor.values[0].value == 1 ? true : false" />
              </div>
            </div>
            <!-- Indicador de apertura de puerta -->
            <div v-if="sensor.type == 10 && sensor.number == 3">
              <div class="card__admin text-center p-2">
                <div class="title__card mb-2">
                  Indicador de apertura de puerta
                </div>
                <Door 
                  :isOpen="sensor.values[0].value == 1 ? true : false" 
                  :value="sensor.values[0].value == 1 ? true : false"
                />
              </div>
            </div>
            <!-- Indicador de bateria 1 -->
            <div v-if="sensor.type == 100">
              <div class="card__admin text-center p-2">
                <div class="title__card mb-2">
                  Bateria
                </div>
                <Battery
                  :value="sensor.values[0].value"
                  :unit="sensor.values[0].unit"
                />
              </div>
            </div>
            <!-- Indicador de bateria 2 -->
            <div v-if="sensor.type == 11">
              <div class="card__admin text-center p-2">
                <div class="title__card mb-2">
                  Bateria
                </div>
                <Battery
                  :value="sensor.values[0].value"
                  :unit="sensor.values[0].unit"
                />
              </div>
            </div>
          </div>
        </div>
      </smart-widget>
    </smart-widget-grid>
  </div>
</template>

<script>
import Ligthbuld from "./LightBulb";
import Door from "./Door";
import Powerplant from "./Powerplant";
import Battery from "./Battery";
import FillGauge from "./FillGauge";
import VGauge from "vgauge";
import moment from "moment";
moment.locale("es");

export default {
  name: "Widget",
  props: ["devices", "layout"],

  data() {
    return {
      layout2: [
        { x: 0, y: 0, w: 12, h: 5, i: "0" },
        { x: 0, y: 2, w: 12, h: 5, i: "1" },
      ],
      options: {
        color: "#6ab23c",
        "empty-color": "#2463a7",
        size: 120,
        thickness: 5,
        "empty-thickness": 3,
        "line-mode": "out 5",
        animation: "rs 700 1000",
        "font-size": "1.8rem",
        "font-color": "black",
      },
      width: "300px",
      height: "150px",
      options2: {
        angle: -0.22,
        lineWidth: 0.28,
        radiusScale: 0.8,
        pointer: {
          length: 0.6,
          strokeWidth: 0.035,
          color: "#000000",
        },
        limitMax: false,
        limitMin: false,
        colorStart: "#6FADCF",
        colorStop: "#6ab23c",
        strokeColor: "#E0E0E0",
        generateGradient: true,
        highDpiSupport: true,
      },
      width1: "150px",
      height1: "110px",
      options3: {
        angle: -0.22,
        lineWidth: 0.28,
        radiusScale: 0.8,
        pointer: {
          length: 0.6,
          strokeWidth: 0.035,
          color: "#000000",
        },
        limitMax: false,
        limitMin: false,
        colorStart: "#6FADCF",
        colorStop: "#6ab23c",
        strokeColor: "#E0E0E0",
        generateGradient: true,
        highDpiSupport: true,
      },
      draggable: true,
      resizable: true,
      colNum: 12,
    };
  },
  
  components: {
    Ligthbuld,
    Door,
    Powerplant,
    VGauge,
    Battery,
    FillGauge,
  },

  methods: {
    sendStatistics(mac, area, enterprise) {
      const me = this;
      let initial_date = (moment().subtract(1, "hour"),
      moment().subtract(1, "hour")).format("YYYY-MM-DD hh:mm:ss");
      let final_date = (moment(), moment()).format("YYYY-MM-DD hh:mm:ss");
      me.$router.push({
        name: "graphics",
        params: {
          mac: mac,
          interval: 'null',
          area: area,
          enterprise: enterprise,
          dateTimeInit: initial_date,
          datetimeFin: final_date,
        },
      });
    },
  },
};
</script>
<style scoped>
.smartwidget {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.5rem !important;
  border: none !important;
}
</style>
